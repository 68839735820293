import React from "react";
import DefaultHero from '../../components/DefaultHero';
import Footer from "../../components/Footer";
import QueryString from "../../helper/QueryString";
import { graphql } from "gatsby";
import Helmet from 'react-helmet';
import Navbar from "../../components/navbar";
import PromoBar from "../../components/PromoBar";

export default ({ data, pageContext }) => {
    const image = 'default/alarmanlagen-von-der-nr-1-fuer-sicherheit-3.jpg';
    const city = {
        slug: '/'
    };
    const contextData = {
        title: 'Preisrechner',
        image: '/img/hero/' + image,
        image768: '/img/hero/empty.gif',
        image1024: '/img/hero/' + image,
        image1216: '/img/hero/' + image,
        image1408: '/img/hero/' + image,
        showPriceCalculator: true,
        defaultCssClass: 'price-hero',
        showBottom: true,
        special: 'partner'
    };

    let query = new QueryString();
    query.setSpecial('partner');

    return (
        <React.Fragment>
                <header className="header">
                    <Helmet>
                        <html className='has-navbar-fixed-top' />
                        <title>Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24</title>
                        <meta name="description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                        <meta name="keywords" content="" />
                        <meta property="og:type" content="article" />
                        <meta property="og:title" content='Jetzt Preis für Ihre Alarmanlage berechnen | MeinAlarm24' />
                        <meta property="og:description" content="Berechnen Sie in wenigen Minuten den Preis für Ihre Alarmanlage. ✓ Kostenlos ✓ Sicher ✓ Festpreis " />
                        <meta property="og:image" content={image} />
                    </Helmet>
                    <PromoBar context={contextData} />
                    <Navbar context={contextData} />
                </header>
                <DefaultHero context={contextData}/>
                <Footer small={true}/>
        </React.Fragment>
    );
};

export const query = graphql`
  query PartnerPriceCalcQuery {
    site {
        siteMetadata {
            title
            keywords
            description
            headerImage
            ogTitle
            ogDescription
            image
            image768
            image1024
            image1216
            image1408
            priceMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
            }
        }

    }
  }
`;
